import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from '@mui/material';
import '../../theme/css/custom-styles.css';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

TableHeader.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      alignRight: PropTypes.bool,
      headCellStyles: PropTypes.object,
      sortable: PropTypes.bool,
      labelStyles: PropTypes.object, // Add this line for label styles
    })
  ),
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function TableHeader({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}) {
  const createSortHandler = (property, sortable) => (event) => {
    if (sortable) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ background: 'none', ...headCell.headCellStyles }}
          >
            {headCell.sortable ? (
              <TableSortLabel
                hideSortIcon={!headCell.sortable}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id, headCell.sortable)}
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  className="text-color"
                  sx={headCell.labelStyles} // Apply custom label styles
                >
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography
                variant="subtitle2"
                noWrap
                className="text-color"
                sx={headCell.labelStyles}
              >
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}