import React from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';
import Iconify from '../iconify';

const GenericTable = ({ data, columns, emptyRows, handleOpenMenu }) => {
  return (
    <TableBody sx={{ padding: '0 10px' }}>
      {data.map((row, index) => (
        <TableRow hover key={index} tabIndex={-1} role="checkbox">
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align || 'left'}
              component={column.isHeader ? 'th' : 'td'}
              scope={column.isHeader ? 'row' : null}
              style={column.style || null}
            >
              {column.render ? (
                column.render(row)
              ) : (
                <Typography variant="subtitle2" noWrap>
                  {row[column.id]}
                </Typography>
              )}
            </TableCell>
          ))}
          {columns.some((column) => column.icon) && (
            <TableCell align="center">
              {columns.map((column) => (
                column.icon && (
                  <IconButton
                    key={column.id}
                    size="large"
                    color={column.iconColor || 'inherit'}
                    onClick={(event) => column.handleClick(event, row)}
                  >
                    <Iconify
                      icon={column.icon}
                      width={column.iconWidth || null}
                      height={column.iconHeight || null}
                    />
                  </IconButton>
                )
              ))}
            </TableCell>
          )}
        </TableRow>
      ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={columns.length + 1} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default GenericTable;
