import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Toolbar, Stack } from '@mui/material';
import LabeledInput from '../../../components/labeled-button/labeled-button';
import '../../../theme/css/custom-styles.css';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

export default function UserListToolbar({ inputs }) {
  return (
    <StyledRoot className='container-no-padding container-margin'>
      <Stack direction="row" justifyContent="space-between" mb={5} padding={0}>
        {inputs.map((input, index) => (
          <LabeledInput
            key={index}
            {...input}
          />
        ))}
      </Stack>
    </StyledRoot>
  );
}

UserListToolbar.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
      isDatePicker: PropTypes.bool,
      flexGrow: PropTypes.number,
    })
  ).isRequired,
};