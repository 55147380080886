import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import BellcomCustomer from './pages/BellcomCustomer';
import QrRequests from './pages/QrRequestPage';
import QrInfoPage from './pages/QrInfoPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import auth from './firebase/firebase';
import { useAuth } from './hooks/AuthProvider';


// ----------------------------------------------------------------------

const ProtectedRoute = ({ render, ...rest }) => {
  const isAuthenticated = !!auth.currentUser; // Check if the user is authenticated
  
  return isAuthenticated ? render() : <Navigate to="/login" />;
};

export default function Router() {
  const user = useAuth();
  let dashboardChildren = [];
  if(user?.id_role === 1) {
    dashboardChildren = [
      { path: 'user', element: <ProtectedRoute render={() => <BellcomCustomer />}/> },
      { path: 'qr/requests', element: <ProtectedRoute render={() => <QrRequests />} />},
      { path: 'qr/info', element: <ProtectedRoute render={() => <QrInfoPage />}/> },
    ]
  }
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: dashboardChildren,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '*',
      element: <Navigate to="/login" /> 
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
}
