// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Usuarios',
    path: '/dashboard/user',
  },
  {
    title: 'Solicitudes',
    path: '/dashboard/qr/requests',
  },
  {
    title: 'QRs Bellcom',
    path: '/dashboard/qr/info',
  },
  /*
  {
    title: 'login',
    path: '/login',
  },
  */
];

export default navConfig;
