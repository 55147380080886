import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const PaginationButton = ({ label, isActive, onClick, isFirst, isLast, disabled }) => {

    const buttonStyle = {
        margin: '0',
        border: '1px solid #cccccc',
        background: isActive ? '#911109' : disabled ? '#dedede' : 'white',
        color: isActive ? 'white' : disabled ? '#848484' : '#911109',
        borderRadius: '0',
        borderTopLeftRadius: isFirst ? '5px' : '0',
        borderBottomLeftRadius: isFirst ? '5px' : '0',
        borderTopRightRadius: isLast ? '5px' : '0',
        borderBottomRightRadius: isLast ? '5px' : '0',
        minWidth: '30px',
        height: '30px',
        maxWidth: 'auto',

        ...(isFirst || isLast
            ? {}
            : 
            {
                padding: "0 10px 0 10px"
            }
        )
    };

    return (
        <Button
            onClick={onClick}
            variant="outlined"
            style={buttonStyle}
            disabled={disabled}
        >
            {label}
        </Button>
    );
};

PaginationButton.propTypes = {
    label: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default PaginationButton;
