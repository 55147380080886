import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

const DynamicPopover = ({ open, anchorEl, handleCloseMenu, menuItems, ...otherProps }) => {
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
      {...otherProps}
    >
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.onClick} sx={item.sx}>
          {item.label}
        </MenuItem>
      ))}
    </Popover>
  );
};

DynamicPopover.propTypes = {
    open: PropTypes.any,
    anchorEl: PropTypes.any,
    handleCloseMenu: PropTypes.func.isRequired,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        sx: PropTypes.object,
      })
    ).isRequired,
  };
  

export default DynamicPopover;