import { useState } from 'react'; // Don't forget to import useState

import { styled, alpha } from '@mui/material/styles';
import '../../theme/css/date-picker.css';

import PropTypes from 'prop-types';
import { InputAdornment, Stack, OutlinedInput, InputLabel, MenuItem, Select, Container, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TodayIcon from '@mui/icons-material/Today';
import InputIcon from "react-multi-date-picker/components/input_icon"

import DatePicker from "react-multi-date-picker"

import Iconify from '../iconify';

import "../../theme/css/custom-styles.css"

const styles = {
  select: {
    minWidth: '250px', 
    height: 35,
    background: "white",
    border: "0",
    borderRadius: 0,
    paddingRight: 5
  },
  menuItem: {
    fontSize: '14px', 
  },
  icon: {
    fill: '#911109',
    cursor: 'pointer'
  },
};


  const StyledSearch = styled(OutlinedInput)(({ theme, isAnimated, overrideStyles }) => ({
    width: 240, 
    padding: 0,
    height: 35,
    borderRadius: 0,
    background: 'white',
    transition: isAnimated
      ? theme.transitions.create(['box-shadow', 'width'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter,
        })
      : 'none', 
    marginBottom: isAnimated ? theme.spacing(2) : '0', 
    '&.Mui-focused': {
      width: isAnimated ? 320 : 240,
      boxShadow: theme.customShadows.z8,
      ...overrideStyles
    },
    '& fieldset': {
      borderWidth: `0px !important`,
      borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
      ...overrideStyles
    },
    ...overrideStyles,
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function CustomButton({ direction, handleClick, disabled }) {
    return (
      <Button
        onClick={handleClick}
        style={{
          fontWeight: "bold",
          color: "#080040",
          backgroundColor: "#F1F6FF",
          borderRadius: "50%",
          minWidth: "7px", 
          minHeight: "7px",
          height: "7px", 
          width: "7px",
          marginLeft: direction === "left" ? "0" : "20px",
          marginRight: direction === "left" ? "0" : "10px",
          padding: "10px"
        }}
        className={"cursor-pointer"}
      >
        {direction === "right" ? ">" : "<"}
      </Button>
    )
  }

  const LabeledInput = ({
    label,
    onChange,
    value,
    icon,
    sx,
    isDatePicker,
    isSelectInput,
    options,
    isDateRangePicker, 
    startDate,
    endDate, 
    overrideStyles,
    isActive,
    isButton,
    buttonText, 
    buttonOnClick,
  }) => {
    return (
      <Stack direction="column" alignItems="left" mb={5} sx={sx}>
        <InputLabel
          component="div"
          variant="subtitle1"
          display="flex"
          flexDirection="column"
          className="text-color text-margin-bottom-label"
          id={label}
        >
          {label}
        </InputLabel>
        { 
          isSelectInput ? (
          <>
            <Select
              displayEmpty
              value={value || options[0]}
              onChange={onChange}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              style={styles.select} 
              inputProps={{ 'aria-label': 'Without label' }}
              IconComponent={(props) => (
                <ArrowDropDownIcon
                  {...props}
                  style={{ ...styles.icon, ...props.style }}
                />
              )}            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={styles.menuItem}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </>
          

        ) : isDateRangePicker ?
            <DatePicker range 
              dateSeparator=" - " 
              headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]} 
              monthYearSeparator=" " 
              weekDays={["D", "L", "M", "X", "J", "V", "S"]}
              months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
              renderButton={<CustomButton />}
              render={<InputIcon />}
              format="YYYY-MM-DD"
              onChange={onChange}
              value={value}
              rangeHover
            />
        : isButton ? (
          // Render as a button
          <Button variant="soft" onClick={buttonOnClick} sx={overrideStyles}>
            {buttonText}
          </Button> 
        ) : (
          <StyledSearch
            id={label}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            endAdornment={
              icon === '' ? null : (
                <InputAdornment position="start">
                  <Iconify
                    icon={icon}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              )
            }
            isAnimated={false}
            overrideStyles={overrideStyles}
            disabled={isActive === false ? true : undefined}
          />
        )}
      </Stack>
    );
};

LabeledInput.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    sx: PropTypes.object,
    icon: PropTypes.string,
    isDatePicker: PropTypes.bool,
    overrideStyles: PropTypes.any,
    isDateRangePicker: PropTypes.bool,
    isActive: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    buttonText: PropTypes.string,
    buttonOnClick: PropTypes.func, 
};

export default LabeledInput;