class StatusTranslator {
    constructor() {
        this.statusMap = {
            '1': { text: 'Creado', image: '/assets/images/active/oval_yellow.png' },
            '2': { text: 'Activo', image: '/assets/images/active/oval_green.png' },
            '3': { text: 'Inactivo', image: '/assets/images/active/oval_red.png' },
            'created': { text: 'Creado', image: '/assets/images/active/oval_yellow.png' },
            'activated': { text: 'Activo', image: '/assets/images/active/oval_green.png' },
            'deleted': { text: 'Inactivo', image: '/assets/images/active/oval_red.png' },
        };

        this.statusIds = {
            CREATED: 'CREATED',
            ACTIVE: 'ACTIVATED',
            DELETED: 'DELETED',
        };
    }

    translate(status) {
        const lowercaseStatus = status.toString().toLowerCase();
        return this.statusMap[lowercaseStatus] || { text: status, image: null };
    }

    translateToId(text) {
        const lowercaseText = text.toLowerCase();
        const entry = Object.entries(this.statusMap).find(
            ([key, value]) => value.text.toLowerCase() === lowercaseText
        );
        return entry ? entry[0] : null; 
    }
}

export default StatusTranslator;