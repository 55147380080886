import { faker } from '@faker-js/faker';

const generateRandomQRRequest = () => {
    return {
        "id": faker.datatype.uuid(),
        "customer_id": faker.datatype.number(),
        "fullname": faker.name.fullName(),
        "phone": faker.phone.number(),
        "address": faker.address.streetAddress(),
        "cp": faker.helpers.arrayElement(["", faker.datatype.number({ min: 1000, max: 9999 }), null]),
        "email": faker.internet.email(),
        "qrId": faker.helpers.arrayElement(["", faker.datatype.number({ min: 1000, max: 9999 }), null]),
        "city": faker.address.city(),
        "zip_code": faker.address.zipCode(),
        "status": faker.helpers.arrayElement(["deactivated", "activated", "pending"])
    };
};

const QR_REQUEST = [
    generateRandomQRRequest(),
    generateRandomQRRequest(),
    generateRandomQRRequest(),
    generateRandomQRRequest(),
    generateRandomQRRequest(),
    generateRandomQRRequest(),
    generateRandomQRRequest(),
    generateRandomQRRequest(),
];

export default QR_REQUEST