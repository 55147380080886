import axios from 'axios';

const token = process.env.REACT_APP_ID_TOKEN;

const axiosInstance = axios.create({
  withCredentials: false,
});

class APIUtils {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  async signupCustomer(data) {
    const response = await axiosInstance.post(`${this.baseURL}/customers/signup`, data);

    if (response.status === 201) {
      return response.data;
    }

    if (response.status === 409) {
      throw new Error(response.data.message);
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async updateCustomer(customerId, data) {
    const response = await axiosInstance.put(`${this.baseURL}/customers/${customerId}/update`, data);
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async deleteCustomer(customerId) {
    const response = await axiosInstance.delete(`${this.baseURL}/customers/${customerId}/delete`);

    if (response.status === 204) {
      return true;
    }

    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async createBellcomRequest(data) {
    const response = await axiosInstance.post(`${this.baseURL}/bellcom-requests`, data);

    if (response.status === 201) {
      return response.data;
    }
    if (response.status === 401) {
      throw new Error('Invalid bellcom request.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async getAllBellcomRequestsInInterval(params) {
    const response = await axiosInstance.get(`${this.baseURL}/bellcom-requests/interval_status`, {
      params,
    });

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('No bellcom requests found in the interval.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async getBellcomRequest(requestId) {
    const response = await axiosInstance.get(`${this.baseURL}/bellcom-requests/${requestId}`);

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Bellcom request not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async updateBellcomRequest(requestId, bellcomBody) {
    const response = await axiosInstance.patch(`${this.baseURL}/bellcom-requests/${requestId}`, bellcomBody);
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Bellcom request not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async updateBellcomRequestStatus(requestId, status, bellcomId) {
    const response = await axiosInstance.patch(`${this.baseURL}/bellcom-requests/${requestId}/update_status`, null, {
      params: {
        status,
        bellcom_id: bellcomId
      },
    });

    if (response.status === 200) {
      return true;
    }
    if (response.status === 404) {
      throw new Error('Bellcom request not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async getBellcomListByCustomer(customerId) {
    const response = await axiosInstance.get(`${this.baseURL}/customers/${customerId}/bellcoms`);

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    }
    if (response.status === 400) {
      throw new Error('No bellcoms found for the customer.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async getCustomer(customerId) {
    const response = await axiosInstance.get(`${this.baseURL}/customers/${customerId}`);

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async createBellcom(bellcom) {
    const response = await axiosInstance.post(`${this.baseURL}/bellcoms`, bellcom);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async getBellcomById(bellcomId) {
    const response = await axiosInstance.get(`${this.baseURL}/bellcoms/${bellcomId}`);

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Bellcom not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async deleteBellcom(bellcomId) {
    const response = await axiosInstance.delete(`${this.baseURL}/bellcoms/${bellcomId}`);

    if (response.status === 204) {
      return true;
    }
    if (response.status === 404) {
      throw new Error('Bellcom not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async updateBellcom(bellcomId, data) {
    const response = await axiosInstance.patch(`${this.baseURL}/bellcoms/${bellcomId}`, data);
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Bellcom not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async activateBellcom(bellcomId, data) {
    const response = await axiosInstance.patch(`${this.baseURL}/bellcoms/${bellcomId}/activate`, data);

    if (response.status === 200) {
      return response.data;
    }

    if (response.status === 404) {
      throw new Error('Bellcom not found.');
    }

    if (response.status === 409) {
      throw new Error('Bellcom is already active.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async createCallRecord(callRecordData) {
    const response = await axiosInstance.post(`${this.baseURL}/call-records`, callRecordData);

    if (response.status === 201) {
      return response.data;
    }
    if (response.status === 400) {
      throw new Error('Invalid call record information.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async updateCallRecordStatus(callRecordId, statusData) {
    const response = await axiosInstance.patch(`${this.baseURL}/call-records/${callRecordId}/status`, statusData);

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Call record not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async listCallsByCustomer(customerId) {
    const response = await axiosInstance.get(`${this.baseURL}/customers/${customerId}/calls`);

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async listQrCodes(params) {
    
    const response = await axiosInstance.get(`${this.baseURL}/bellcoms/list`, { params });
    console.log(response)
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async generateQrCode(bellcomId) {

    const response = await axiosInstance.get(`${this.baseURL}/bellcom/${bellcomId}/url`);
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async listCustomersWithBellcoms(params) {

    const response = await axiosInstance.get(`${this.baseURL}/customers`, { params });
    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 404) {
      throw new Error('Customer not found.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }

  async login(username, password) {
    const response = await axiosInstance.post(`${this.baseURL}/login`, {
      username,
      password
    });

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 401) {
      throw new Error('Username or password is incorrect.');
    } else {
      throw new Error('An error occurred during the API call.');
    }
  }
}

export default APIUtils;