import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer, Slide, } from 'react-toastify';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { APIProvider } from './hooks/APIContext';
import { AuthProvider } from "./hooks/AuthProvider";

import 'react-toastify/dist/ReactToastify.css';


// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <APIProvider>
              <AuthProvider>
                <ToastContainer transition={Slide} limit={3}/>
                <Router />
              </AuthProvider>
            </APIProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
