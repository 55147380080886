import { useState } from 'react';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import LabeledInput from '../labeled-button/labeled-button';
import DynamicPopover from '../dynamic-pop-over'
import StatusTranslator from "../../utils/bellcomStatus"
import { useAPI } from '../../hooks/APIContext';

import '../../theme/css/custom-styles.css';

const QrInfoPopUp = ({ onClosePopUp, open, qrCode }) => {
  const api = useAPI();
  const statusTranslator = new StatusTranslator();

  const [openPopOver, setOpenPopOver] = useState(false);

  const [currentQrCode, setCurrentQrCode] = useState({ ...qrCode });


  const handleInputChange = (label, value) => {
    setCurrentQrCode(prevCustomer => ({
      ...prevCustomer,
      [label]: value
    }));
  };

  const menuItems = [
    {
      label: 'Pendiente',
      onClick: () => {
        setQrStatus(statusTranslator.statusIds.CREATED);
        handleCloseMenu();
      },
    },
    {
      label: 'Activo',
      onClick: () => {
        setQrStatus(statusTranslator.statusIds.ACTIVE);
        handleCloseMenu();
      },
    },
    {
      label: 'Desactivado',
      onClick: () => {
        setQrStatus(statusTranslator.statusIds.DELETED);
        handleCloseMenu();
      },
    },
  ];

  const onClickBellcom = (event) => {
    setOpenPopOver(event.currentTarget)
  }

  const onSave = async () => {
    await api.updateBellcom(currentQrCode.id, currentQrCode);
    toast.success(`Actualizado correctamente`, {
      position: 'top-right',
      autoClose: 3000, 
    });
    onClosePopUp();
  }

  const setQrStatus = (status) => {
    console.log(currentQrCode.status, status)
    if (currentQrCode.status === status) return;
    const newQrCode = { ...currentQrCode };
    newQrCode.status = status;
    setCurrentQrCode(newQrCode);
  }

  const handleCloseMenu = () => {
    setOpenPopOver(null);
  };

  const buttonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const arrowStyles = {
    color: '#911109',
    fontSize: '32px',
  };

  const handleClose = () => {
    onClosePopUp(false);
  };

  const originalDateString = currentQrCode.creation_date;
  const dateObject = new Date(originalDateString);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day}`;

  const status = statusTranslator.translate(currentQrCode.status);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: "500px",
        }}
      >
        <>
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 0,
              minWidth: 300,
              maxWidth: '80%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#FAF5EF'
            }}
          >
            <Box
              sx={{
                bgcolor: '#911109',
                color: 'white',
                padding: '16px',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#ffffff', fontWeight: 100, margin: 0 }}>
                Detalle de QR
              </Typography>
            </Box>

            <Container sx={{
              flexDirection: "row",
              display: "flex",
              paddingTop: "30px"
            }}>
              <LabeledInput
                label='ID QR bellcom'
                flexGrow={1}
                overrideStyles={{
                  width: 450,
                  marginRight: 40
                }}
                value={currentQrCode.id}
                isActive={false}
              />
              <LabeledInput label='Usuario asociado' flexGrow={1}
                overrideStyles={{
                  width: 450,
                  marginRight: 40
                }}
                value={currentQrCode.owner_full_name}
                onChange={value => handleInputChange('owner_full_name', value)}
              />
            </Container>

            <Container sx={{
              flexDirection: "row",
              display: "flex",
            }}>
              <LabeledInput label='Nº de teléfono' flexGrow={1}
                overrideStyles={{
                  width: 450,
                  marginRight: 42
                }}
                value={currentQrCode.phone_number}
                onChange={value => handleInputChange('phone_number', value)}
              />
              <LabeledInput
                overrideStyles={{
                  width: 150,
                  marginRight: 35
                }}
                label='Fecha de Activación'
                flexGrow={1}
                value={formattedDate}
                isActive={false}
              />
            </Container>

            <Container sx={{
              flexDirection: "row",
              display: "flex",
            }}>
              <LabeledInput label='Calle y número' flexGrow={1}
                overrideStyles={{
                  width: 450,
                  marginRight: 42
                }}
                value={currentQrCode.address}
                onChange={value => handleInputChange('address', value)}
              />
              <LabeledInput label='Código Postal' flexGrow={1}
                overrideStyles={{
                  width: 200,
                  marginRight: 42
                }}
                value={currentQrCode.zip_code}
                onChange={value => handleInputChange('zip_code', value)}
              />
              <LabeledInput label='Ciudad' flexGrow={1}
                overrideStyles={{
                  width: 350,
                }}
                value={currentQrCode.city}
                onChange={value => handleInputChange('city', value)}
              />
            </Container>


            <Container sx={{
              flexDirection: "column",
              display: "flex",
              paddingTop: "10px",
              paddingBottom: "30px"
            }}>
              <Typography component="div" variant="subtitle1" display="flex" flexDirection="column" className='text-color'>
                Estado
              </Typography>
              <Container
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '30px',
                  backgroundColor: 'white',
                  borderBottom: '1px solid #cccccc',
                  height: '50px',
                  padding: '0',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    maxWidth: '120px',
                  }}
                  className='container-no-padding'
                >
                  <div>
                    <img
                      src={status.image}
                      alt={status.text}
                    />
                  </div>
                  <Typography component="div" variant="subtitle1" className='row-text-color'>
                    {status.text}
                  </Typography>
                </div>
                <Button style={buttonStyles} onClick={(event) => onClickBellcom(event)}>
                  <ArrowDropDownIcon style={arrowStyles} />
                </Button>
              </Container>
            </Container>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '16px',
              }}
            >
              <Button
                onClick={onSave}
                sx={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  height: "50px",
                  width: "30%",
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#63CD71',
                  },
                }}>
                Guardar
              </Button>
            </Container>
          </Box>
          <DynamicPopover
            open={openPopOver}
            anchorEl={openPopOver}
            handleCloseMenu={handleCloseMenu}
            menuItems={menuItems}
          />
        </>
      </Modal>
    </>
  );
}

QrInfoPopUp.propTypes = {
  onClosePopUp: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  qrCode: PropTypes.object,
};

export default QrInfoPopUp;