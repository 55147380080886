import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import '../theme/css/custom-styles.css';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import { TableHeader, GenericTable } from '../components/table';
import DynamicPopover from '../components/dynamic-pop-over';
import Scrollbar from '../components/scrollbar';
import PaginationButton from '../components/pagination-button';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
import { CustomerPopUp } from '../components/pop-ups'
import { useAPI } from '../hooks/APIContext';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BellcomCustomer() {

  const api = useAPI();

  const pageSize = 5;
  
  const [customers, setCustomers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  const [params, setParams] = useState(
    {
      search_text: "",
      start_date: "2022-01-01",
      end_date: "2025-01-01",
      limit: pageSize,
      page: 1,
    }
  );

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [selectedDate, setSelectedDate] = useState(null);

  const [openEditPopUp, setOpenEditPopUp] = useState(false);

  const [fetchingData, setFetchingData] = useState(false);

  const [menuPopOver, setMenuPopOver] = useState([])

  const closePopUp = () => {
    setOpenEditPopUp(false);
    setParams(
      {
        search_text: "",
        start_date: "2022-01-01",
        end_date: "2025-01-01",
        limit: pageSize,
        page: 1,
      }
    )
    setSelectedDate(null)
  }

  const fetchCustomers = async () => {
    try {
      setFetchingData(true);
      const response = await api.listCustomersWithBellcoms(params);
      setCustomers(response.customers);
      setTotalItems(response.total_customers);
      setFetchingData(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }

  useEffect(() => {
    fetchCustomers();
  }, [api, params]);


  const handleDateChange = (data, date) => {
    const { validatedValue } = date;
    if (validatedValue && validatedValue.length === 2) {
      const [date1, date2] = validatedValue.map((dateString) => new Date(dateString));
      
      const startDate = date1 < date2 ? date1.toISOString().split('T')[0] : date2.toISOString().split('T')[0];
      const endDate = date1 < date2 ? date2.toISOString().split('T')[0] : date1.toISOString().split('T')[0];
    
      setParams((prevState) => ({
        ...prevState,
        start_date: startDate,
        end_date: endDate,
        page: 1,
      }));
    }
    setSelectedDate(data)
  };

  const handleOpenMenu = (event, data) => {
    setOpen(event.currentTarget);
    setSelected(data)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilter = (value) => {
    setPage(0);
    setFilterName(value);
    setParams((prevParams) => {
      return {
        ...prevParams,
        search_text: value
      }
    })
  };

  const getMenuItems = (data) => {
    return [
      {
        label: 'Editar',
        onClick: () => {
          setOpenEditPopUp(true);
          handleCloseMenu();
        },
      },
      {
        label: 'Eliminar',
        onClick: () => {
          deleteCustomer(data);
          handleCloseMenu();
        },
        sx: { color: 'error.main' },
      },
    ];
  }

  const deleteCustomer = async (data) => {
    await api.deleteCustomer(data.id_customer);
    toast.success(`Usuario eliminado correctamente`, {
      position: 'top-right',
      autoClose: 3000, 
    });
    setParams(
      {
        search_text: "",
        limit: pageSize,
        page: 1,
        status: null,
      }
    )
    setSelectedDate(null);
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const inputConfigs = [
    {
      label: 'Usuario, email o teléfono',
      icon: 'eva:search-fill',
      onChange: handleFilter,
      value: params.search_text,
    },
    {
      label: 'Fecha de creación',
      onChange: handleDateChange,
      value: selectedDate,
      isDateRangePicker: true,
      flexGrow: 1,
      sx: { marginLeft: '50px' },
    },
  ];

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

  const isNotFound = !customers.length;

  const handleChangePage = (newPage) => {
    setParams((prevState) => {
      return {...prevState, page: newPage}
    });
  };

  const handlePageChange = (newPage, onPageChange, totalPages) => {
    if (newPage >= 0 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  function CustomPagination({ page, count, rowsPerPage, onPageChange}) {
    const totalPages = Math.ceil(count / rowsPerPage);
    return (
      <Container className="container-no-padding">
        <PaginationButton
          label="Anterior"
          onClick={() => {
            handlePageChange(page - 1, onPageChange, totalPages);
          }}
          isActive={false}
          disabled={page === 1}
          isFirst
        />
        {[...Array(totalPages)].map((_, index) => (
          <PaginationButton
            key={index}
            label={`${index + 1}`}
            onClick={() => {
              handlePageChange(index + 1, onPageChange, totalPages); // Adjust the page value here
            }}
            isActive={page === index + 1}
          />
        ))}
        <PaginationButton
          label="Siguiente"
          onClick={() => {
            handlePageChange(page + 1, onPageChange, totalPages);
          }}
          isActive={false}
          disabled={page === totalPages}
          isLast
        />
      </Container>
    );
  }

  const tableHeader = [
    { id: 'full_name', label: 'Nombre de Usuario', alignRight: false, sortable: true },
    { id: 'email', label: 'Email', alignRight: false, sortable: false },
    { id: 'phone_number', label: 'Nº teléfono', alignRight: false, sortable: false },
    { id: 'bellcoms', label: 'QRs Bellcom Asociados', alignRight: false, sortable: false },
    { id: '', label: '', alignRight: false, sortable: false, labelStyles: { fontSize: '14px', color: "black", display: "flex", justifyContent: "center", verticalAlign: "center" } },
    { id: 'actions', label: '+', alignRight: false, sortable: false, labelStyles: { fontSize: '20px', color: "black", display: "flex", justifyContent: "center", verticalAlign: "center" } },
  ];

  const columns = [
    { id: 'full_name', label: 'full_name', },
    { id: 'email', label: 'email', align: 'left' },
    { id: 'phone_number', label: 'phone_number', align: 'left' },
    { id: 'bellcoms', label: 'phone_number', align: 'left', render: (row) => (row.bellcoms.map(item => `#${item.id_bellcom}`).join(',')) },
    {
      id: 'actions',
      iconButton: true,
      icon: 'eva:more-horizontal-fill',
      iconColor: 'secondary',
      handleClick: (event, data) => {
        handleOpenMenu(event, data);
        setMenuPopOver(getMenuItems(data));
      },
      iconWidth: 35,
      iconHeight: 35,
    },
  ];

  return (
    <>
      <Helmet>
        <title> Usuarios | Bellcom </title>
      </Helmet>
      <Container sx={{ borderRadius: 0, background: "none" }}>
        {
          openEditPopUp ? <div><CustomerPopUp customer={selected} onClosePopUp={closePopUp} open={openEditPopUp} /></div> : null
        }
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom sx={{ color: '#911109', fontSize: 20 }}>
            Usuarios bellcom
          </Typography>
          <Typography variant="paragraph" gutterBottom sx={{ color: '#000000', fontSize: 15 }}>
            Gestion de Usuarios
          </Typography>
        </Stack>

        <UserListToolbar inputs={inputConfigs} />
        <Container sx={{ borderRadius: 0, background: "none", padding: "0" }} className='container-no-padding'>
          <Scrollbar>
            <Card sx={{ borderRadius: 0 }}>
              <TableContainer sx={{ minWidth: 800, borderRadius: 0 }}>
                <Table>
                  <TableHeader
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHeader}
                    onRequestSort={handleRequestSort}
                  />
                  {fetchingData ? <CircularProgress /> :
                    (
                      <>
                        <GenericTable
                          data={customers}
                          columns={columns}
                          emptyRows={emptyRows}
                          handleOpenMenu={handleOpenMenu}
                        />
                        {isNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <Paper
                                  sx={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <Typography variant="h6" paragraph>
                                    Sin Resultados
                                  </Typography>
                                  {
                                    !!filterName ? 
                                    <Typography variant="body2">
                                      Sin Resultados &nbsp;
                                      <strong>&quot;{filterName}&quot;</strong>.
                                    </Typography>
                                    : 
                                    null
                                  }
                                </Paper>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </>
                    )}
                </Table>
              </TableContainer>
            </Card>
          </Scrollbar>

          {
            totalItems > params.limit ?           
            <Container style={{ marginTop: '20px' }} className='container-no-padding'>
                <CustomPagination
                page={params.page}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Container> : null
          }
        </Container>
      </Container>
      <DynamicPopover
        open={open}
        anchorEl={open}
        handleCloseMenu={handleCloseMenu}
        menuItems={menuPopOver}
      />
    </>
  );
}
