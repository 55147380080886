import { createContext, useContext } from 'react';
import APIUtils from '../requests/requests';

const APIContext = createContext();

export function useAPI() {
  return useContext(APIContext);
}

export function APIProvider({ children }) {
  const url = process.env.REACT_APP_API_URL;
  console.log(`The value of url is: ${url}`);
  const api = new APIUtils(url);

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
}