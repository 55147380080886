class DeliveryStatusTranslator {
    constructor() {
        this.statusMap = {
            'pending': { text: 'Pendiente', image: '/assets/images/active/oval_red.png' },
            'in_transit': { text: 'Enviado', image: '/assets/images/active/oval_yellow.png'},
            'delivered': { text: 'Entregado', image: '/assets/images/active/oval_green.png'},
        };
        this.StatusKeys = Object.freeze({
            PENDING: 'PENDING',
            IN_TRANSIT: 'IN_TRANSIT',
            DELIVERED: 'DELIVERED',
        });
    }

    translate(status) {
        const lowercaseStatus = status.toString().toLowerCase();
        return this.statusMap[lowercaseStatus] || { text: status, image: null };
    }

    translateToId(text) {
        const lowercaseText = text.toLowerCase();
        const entry = Object.entries(this.statusMap).find(
            ([key, value]) => value.text.toLowerCase() === lowercaseText
        );
        
        return entry ? entry[0] : null; 
    }
}

export default DeliveryStatusTranslator;