import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAPI } from './APIContext';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const api = useAPI();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if(user) {
        const userInfo = await getUserInformation(user);
        setCurrentUser(userInfo);
      }
    });

    return () => unsubscribe();
  }, []);

  const getUserInformation = async (firebaseUser) => {
    const userInfo = await api.getCustomer(firebaseUser.uid);
    return userInfo;
  }

  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
