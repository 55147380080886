import { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useAPI } from '../../hooks/APIContext';
import StatusTranslator from '../../utils/bellcomStatus';
import DynamicPopover from '../dynamic-pop-over';
import LabeledInput from '../labeled-button/labeled-button';

import '../../theme/css/custom-styles.css';

const CustomerPopUp = ({ onClosePopUp, open, customer }) => {
  const api = useAPI();
  const translator = new StatusTranslator();

  const [openPopOver, setOpenPopOver] = useState(false);

  const [customerBellcoms, setCustomerBellcoms] = useState([...customer.bellcoms]);

  const [currentBellcom, setCurrentBellcom] = useState(false);

  const [modifiedBellcoms, setModifiedBellcoms] = useState({});

  const [customerState, setcustomerState] = useState({
    ...customer,
  });

  const handleInputChange = (label, value) => {
    setcustomerState((prevCustomer) => ({
      ...prevCustomer,
      [label]: value,
    }));
  };

  const menuItems = [
    {
      label: 'Activar',
      onClick: () => {
        setCustomerQRStatus(translator.statusIds.ACTIVE);
        handleCloseMenu();
      },
    },
    {
      label: 'Desactivar',
      onClick: () => {
        setCustomerQRStatus(translator.statusIds.DELETED);
        handleCloseMenu();
      },
      sx: { color: 'error.main' },
    },
  ];

  const onClickBellcom = (event, bellcom) => {
    setOpenPopOver(event.currentTarget);
    setCurrentBellcom(bellcom);
  };

  const onSave = async () => {
    const values = Object.values(modifiedBellcoms);
    values.forEach((item) => {
      api.updateBellcom(item.id, { status: item.status.toUpperCase() });
    });
    await api.updateCustomer(customerState.id_customer, customerState);
    toast.success(`Usuario actualizado Correctamente`, {
      position: 'top-right',
      autoClose: 3000,
    });
    onClosePopUp();
  };

  const onResetPassword = async () => {


    const auth = getAuth();
    sendPasswordResetEmail(auth, customerState.email)
      .then(() => {
        
        toast.success(`Correo enviado`, {
          position: 'top-right',
          autoClose: 3000,
        });
        onClosePopUp();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error: ${errorMessage}`);
        
        toast.success(`Error enviando correo ${errorMessage}`, {
          position: 'top-right',
          autoClose: 3000,
        });
        onClosePopUp();
      });
  };

  const setCustomerQRStatus = (status) => {
    if (currentBellcom.status === status) return;

    const updatedBellcom = { id: currentBellcom.id_bellcom, status };
    const bellcomMap = {};
    bellcomMap[currentBellcom.id_bellcom] = updatedBellcom;

    const mergedBellcoms = { ...modifiedBellcoms, ...bellcomMap };

    const newBellcomStatus = customerBellcoms.map((bellcom) => {
      if (bellcom.id_bellcom === currentBellcom.id_bellcom) {
        bellcom.status = status;
      }
      return bellcom;
    });
    setModifiedBellcoms(mergedBellcoms);
    setCustomerBellcoms(newBellcomStatus);
  };

  const handleCloseMenu = () => {
    setOpenPopOver(null);
  };

  const buttonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const arrowStyles = {
    color: '#911109',
    fontSize: '32px',
  };

  const handleClose = () => {
    onClosePopUp(false);
  };

  const originalDateString = customer.creation_date;
  const dateObject = new Date(originalDateString);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day}`;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '500px',
        }}
      >
        <>
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 0,
              minWidth: 300,
              maxWidth: '80%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#FAF5EF',
            }}
          >
            <Box
              sx={{
                bgcolor: '#911109',
                color: 'white',
                padding: '16px',
                textAlign: 'left',
                fontSize: '20px',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#ffffff', fontWeight: 100, margin: 0 }}>
                Editar usuario
              </Typography>
            </Box>

            <Container
              sx={{
                flexDirection: 'row',
                display: 'flex',
                paddingTop: '30px',
              }}
            >
              <LabeledInput
                label="Nombre de Usuario"
                flexGrow={1}
                overrideStyles={{
                  width: 450,
                  marginRight: 40,
                }}
                value={customerState.full_name}
                onChange={(value) => handleInputChange('full_name', value)}
              />
              <LabeledInput
                label="Nº de teléfono"
                flexGrow={1}
                overrideStyles={{
                  width: 200,
                  marginRight: 40,
                }}
                value={customerState.phone_number}
                onChange={(value) => handleInputChange('phone_number', value)}
              />
              <LabeledInput
                label="Email"
                flexGrow={1}
                overrideStyles={{
                  width: 380,
                }}
                value={customerState.email}
                onChange={(value) => handleInputChange('email', value)}
                isActive={false}
              />
            </Container>

            <Container
              sx={{
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              <LabeledInput
                overrideStyles={{
                  width: 300,
                  marginRight: 35,
                }}
                label="Fecha de Creación"
                flexGrow={1}
                value={formattedDate}
                isActive={false}
              />

              <LabeledInput
                label="Calle y Número"
                flexGrow={1}
                overrideStyles={{
                  width: 400,
                  marginRight: 20,
                }}
                value={customerState.address}
                onChange={(value) => handleInputChange('address', value)}
              />
              <LabeledInput
                label="Código Postal"
                flexGrow={1}
                overrideStyles={{
                  width: 150,
                  marginRight: 20,
                }}
                value={customerState.zip_code}
                onChange={(value) => handleInputChange('zip_code', value)}
              />
              <LabeledInput
                label="Ciudad"
                flexGrow={1}
                overrideStyles={{
                  width: 180,
                }}
                value={customerState.city}
                onChange={(value) => handleInputChange('city', value)}
              />
            </Container>
            {!customer.bellcoms.length ? null : (
              <Container
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  paddingTop: '10px',
                  paddingBottom: '30px',
                }}
              >
                <Typography
                  component="div"
                  variant="subtitle1"
                  display="flex"
                  flexDirection="column"
                  className="text-color"
                >
                  QRs Bellcoms asociados
                </Typography>

                {customerBellcoms.map((bellcom) => {
                  const status = translator.translate(bellcom.status);
                  console.log(bellcom.id_bellcom, status);
                  return (
                    <Container
                      key={bellcom.id_bellcom}
                      sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: '30px',
                        backgroundColor: 'white',
                        borderBottom: '1px solid #cccccc',
                        height: '50px',
                        padding: '0',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: 1,
                          justifyContent: 'space-between',
                          maxWidth: '300px',
                        }}
                        className="container-no-padding"
                      >
                        <div>
                          <img src={status.image} alt={status.text} />
                        </div>
                        <Typography component="div" variant="subtitle1" className="row-text-color">
                          # {bellcom.id_bellcom}
                        </Typography>
                        <Typography component="div" variant="subtitle1" className="row-text-color">
                          -
                        </Typography>
                        <Typography component="div" variant="subtitle1" className="row-text-color">
                          {status.text}
                        </Typography>
                      </div>
                      <Button style={buttonStyles} onClick={(event) => onClickBellcom(event, bellcom, status)}>
                        <ArrowDropDownIcon style={arrowStyles} />
                      </Button>
                    </Container>
                  );
                })}
              </Container>
            )}
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px', // Adjust as needed
              }}
            >
              <Button
                onClick={onResetPassword}
                sx={{
                  backgroundColor: '#911109',
                  color: '#ffffff',
                  height: '50px',
                  width: '30%',
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#c22a1e',
                  },
                }}
              >
                Recuperar Contraseña
              </Button>
              <Button
                onClick={onSave}
                sx={{
                  backgroundColor: '#000000',
                  color: '#ffffff',
                  height: '50px',
                  width: '30%',
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#63CD71',
                  },
                }}
              >
                Guardar
              </Button>
            </Container>
          </Box>
          <DynamicPopover
            open={openPopOver}
            anchorEl={openPopOver}
            handleCloseMenu={handleCloseMenu}
            menuItems={menuItems}
          />
        </>
      </Modal>
    </>
  );
};

CustomerPopUp.propTypes = {
  onClosePopUp: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  customer: PropTypes.object,
};

export default CustomerPopUp;
