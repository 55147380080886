import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../theme/css/custom-styles.css';

// @mui
import {
  Button,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { QRCodeCanvas } from 'qrcode.react';
import { toast } from 'react-toastify';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DynamicPopover from '../components/dynamic-pop-over';
import PaginationButton from '../components/pagination-button';
import { QrInfoPopUp } from '../components/pop-ups';
import Scrollbar from '../components/scrollbar';
import { GenericTable, TableHeader } from '../components/table';

import { useAPI } from '../hooks/APIContext';

import { UserListToolbar } from '../sections/@dashboard/user';
import StatusTranslator from '../utils/bellcomStatus';

import QR_REQUEST from '../_mock/qr_request';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  return array;
}

export default function QrInfoPage() {
  const api = useAPI();
  const statusTranslator = new StatusTranslator();

  const [fetchingData, setFetchingData] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [qrCodeInfo, setQrCodeInfo] = useState({ url: '', bellcom_id: '' });
  const canvasRef = useRef(null);
  const pageSize = 5;
  const [params, setParams] = useState({
    search_text: '',
    limit: pageSize,
    page: 1,
    status: null,
  });

  const fetchQrCodes = async () => {
    try {
      setFetchingData(true);
      const response = await api.listQrCodes(params);
      setQrCodes(response.bellcoms);
      setTotalItems(response.total_bellcoms);
      setFetchingData(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  useEffect(() => {
    fetchQrCodes();
  }, [api, params]);

  const [selected, setSelected] = useState([]);

  const getMenuItems = (data) => {
    return [
      {
        label: 'Editar',
        onClick: () => {
          setOpenEditPopUp(true);
          handleCloseMenu();
        },
      },
      {
        label: 'Eliminar',
        onClick: () => {
          deleteBellcom(data);
          handleCloseMenu();
        },
        sx: { color: 'error.main' },
      },
    ];
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selectedDate, setSelectedDate] = useState('');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [openEditPopUp, setOpenEditPopUp] = useState(false);

  const [menuPopOver, setMenuPopOver] = useState([]);

  const deleteBellcom = async (data) => {
    await api.deleteBellcom(data.id);
    toast.success(`Bellcom ${data.id} eliminado correctamente`, {
      position: 'top-right',
      autoClose: 3000,
    });
    setParams({
      search_text: '',
      limit: pageSize,
      page: 1,
      status: null,
    });
    setSelectedDate(null);
  };

  const handleDateChange = (data, date) => {
    const { validatedValue } = date;
    if (validatedValue && validatedValue.length === 2) {
      const [date1, date2] = validatedValue.map((dateString) => new Date(dateString));

      const startDate = date1 < date2 ? date1.toISOString().split('T')[0] : date2.toISOString().split('T')[0];
      const endDate = date1 < date2 ? date2.toISOString().split('T')[0] : date1.toISOString().split('T')[0];

      setParams((prevState) => ({
        ...prevState,
        start_date: startDate,
        end_date: endDate,
        page: 1,
      }));
    }
    setSelectedDate(data);
  };

  const handleOpenMenu = (event, data) => {
    setOpen(event.currentTarget);
    setSelected(data);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = QR_REQUEST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setParams((prevState) => {
      return { ...prevState, page: newPage };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilter = (value) => {
    setPage(0);
    setFilterName(value);
    setParams((prevParams) => {
      return {
        ...prevParams,
        search_text: value,
        page: 1,
      };
    });
  };

  useEffect(() => {
    if (qrCodeInfo.url.length) {
      generateQrCode(qrCodeInfo);
    }
  }, [qrCodeInfo]);

  const createQrCode = async () => {
    const bellcomQrInfo = await api.createBellcom({});
    toast.success(`Código QR con ID ${bellcomQrInfo?.id} generado exitosamente`, {
      position: 'top-right',
      autoClose: 3000,
    });
    setParams({
      search_text: '',
      limit: pageSize,
      page: 1,
      status: null,
    });
    setSelectedDate(null);
  };

  const getQrInformation = async (bellcom) => {
    console.log('Executing getQrInformation');
    const bellcomQrInfo = await api.generateQrCode(bellcom.id);
    setQrCodeInfo(bellcomQrInfo);
  };

  function generateQrCode(qrCodeInfo) {
    const canvas = canvasRef.current.children[0];
    console.log('Executing getQrInformation');
    console.log('qrCodeInfo:', qrCodeInfo);
    const imagePath = `/assets/images/qr/qr_template.png`;
    console.log(`${imagePath}/assets`);
    html2canvas(canvas).then((canvasImage) => {
      // eslint-disable-next-line
      const pdf = new jsPDF('p', 'mm', 'a4');
      const image = document.getElementById('qrTemplateImg');
      pdf.addImage(image, 'png',10,10,85,136);
      pdf.addImage(canvasImage.toDataURL('image/png'), 'PNG', 23, 70, 62, 62);
      // Add text to the PDF
      pdf.setFontSize(12); // Set the font size
      pdf.setTextColor(255, 255, 255);
      pdf.text(`BID: ${qrCodeInfo.bellcom_id}`, 30, 140); // Add your text at the desired position (x, y)

      // Save or display the PDF as needed
      pdf.save(`Bellcom_${qrCodeInfo.bellcom_id}.pdf`);
      setQrCodeInfo({ url: '', id: '' });
    });
  }

  const closePopUp = () => {
    setOpenEditPopUp(false);
    setParams({
      search_text: '',
      limit: pageSize,
      page: 1,
      status: null,
    });
    setSelectedDate(null);
  };

  const tableHeaders = [
    { id: 'id', label: 'ID QR Bellcom', alignRight: false },
    { id: 'status', label: 'Estado', alignRight: false },
    { id: 'owner_full_name', label: 'Usuario Asociado', alignRight: false },
    { id: 'phone_number', label: 'Teléfono', alignRight: false },
    { id: 'qrId', label: 'QR (PDF)', alignRight: false },
    { id: 'options', label: '', alignRight: false },
  ];

  const columns = [
    {
      id: 'id',
      label: 'ID QR Bellcom',
      alignRight: false,
      render: (row) => {
        return `# ${row.id}`;
      },
    },
    {
      id: 'status',
      label: 'Estado',
      alignRight: false,
      render: (row) => {
        const currentStatus = statusTranslator.translate(row.status);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <div
              style={{
                marginRight: '15px',
                width: '10px',
                height: '10px',
              }}
            >
              <img src={currentStatus.image} alt={currentStatus.text} />
            </div>
            {currentStatus.text}
          </div>
        );
      },
    },
    { id: 'owner_full_name', label: 'owner_full_name' },
    { id: 'phone_number', label: 'phone_number', align: 'left' },
    {
      id: 'qrId',
      label: 'QR (PDF)',
      alignRight: false,
      render: (row) => {
        return (
          <Button
            onClick={() => getQrInformation(row)}
            style={{
              border: 'none',
              padding: 0,
              cursor: 'pointer',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              margin: 'auto',
              display: 'flex',
            }}
          >
            <img src="/assets/images/qr/qr.png" alt="Is Active" />
          </Button>
        );
      },
    },
    {
      id: 'actions',
      iconButton: true,
      icon: 'eva:more-horizontal-fill',
      iconColor: 'secondary',
      handleClick: (event, data) => {
        handleOpenMenu(event, data);
        setMenuPopOver(getMenuItems(data));
      },
      iconWidth: 35,
      iconHeight: 35,
    },
  ];

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - QR_REQUEST.length) : 0;

  const isNotFound = !qrCodes.length;

  const handlePageChange = (newPage, onPageChange, totalPages) => {
    if (newPage >= 0 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  function CustomPagination({ page, count, rowsPerPage, onPageChange }) {
    const totalPages = Math.ceil(count / rowsPerPage);
    return (
      <Container className="container-no-padding">
        <PaginationButton
          label="Anterior"
          onClick={() => {
            handlePageChange(page - 1, onPageChange, totalPages);
          }}
          isActive={false}
          disabled={page === 1}
          isFirst
        />
        {[...Array(totalPages)].map((_, index) => (
          <PaginationButton
            key={index}
            label={`${index + 1}`}
            onClick={() => {
              handlePageChange(index + 1, onPageChange, totalPages); // Adjust the page value here
            }}
            isActive={page === index + 1}
          />
        ))}
        <PaginationButton
          label="Siguiente"
          onClick={() => {
            handlePageChange(page + 1, onPageChange, totalPages);
          }}
          isActive={false}
          disabled={page === totalPages}
          isLast
        />
      </Container>
    );
  }

  const inputConfigs = [
    {
      label: 'Buscar',
      icon: 'eva:search-fill',
      onChange: handleFilter,
      value: filterName,
    },
    {
      label: 'Fecha de creación',
      onChange: handleDateChange,
      value: selectedDate,
      isDateRangePicker: true,
      flexGrow: 1,
      sx: { marginLeft: '50px' },
    },
    {
      label: 'Estado',
      onChange: (event) => {
        let value = event.target.value;
        if (value === 'all') {
          value = null;
        }
        setParams((prevState) => {
          return { ...prevState, status: value, page: 1 };
        });
      },
      value: params.status || 'all',
      isSelectInput: true,
      flexGrow: 1,
      isAnimated: false,
      sx: { marginLeft: '50px' },
      options: [
        { label: 'Todos', value: 'all' },
        { label: 'Pendiente de activación', value: statusTranslator.statusIds.CREATED },
        { label: 'Activo', value: statusTranslator.statusIds.ACTIVE },
        { label: 'Desactivado', value: statusTranslator.statusIds.DELETED },
      ],
    },
    {
      overrideStyles: {
        backgroundColor: '#8D0800',
        color: 'white',
        border: 'none',
        borderRadius: '0',
        marginLeft: '40px',
        marginTop: '22px',
        height: '35px',
        width: '200px',
        '&:hover': {
          backgroundColor: '#c90a00',
        },
      },
      isButton: true,
      buttonText: '+ Generar QR',
      buttonOnClick: createQrCode,
    },
  ];

  return (
    <>
      <Helmet>
        <title> Información | Bellcom </title>
      </Helmet>
      <Container sx={{ borderRadius: 0, background: 'none' }}>
        {openEditPopUp ? (
          <div>
            <QrInfoPopUp qrCode={selected} setOpen={setOpenEditPopUp} onClosePopUp={closePopUp} open={openEditPopUp} />
          </div>
        ) : null}

        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom sx={{ color: '#911109', fontSize: 20 }}>
            QRs Bellcom
          </Typography>
          <Typography variant="paragraph" gutterBottom sx={{ color: '#000000', fontSize: 15 }}>
            Gestion de QRs
          </Typography>
        </Stack>

        <UserListToolbar inputs={inputConfigs} />
        <div>
        <img id="qrTemplateImg" style={{ display: 'none' }} src="/assets/images/qr/qr_template.png" alt="TemplateQR"/>
        </div>

        <Container sx={{ borderRadius: 0, background: 'none', padding: '0' }} className="container-no-padding">
          <Scrollbar>
            <div ref={canvasRef} className="canvas-container">
              <QRCodeCanvas
                id="qrcode"
                bgColor="#ffffff"
                fgColor="#000000"
                value={qrCodeInfo.url}
                imageSettings={{
                  bgColor: '#000000',
                  height: 500,
                  width: 500,
                }}
              />
            </div>
            <Card sx={{ borderRadius: 0 }}>
              <TableContainer sx={{ minWidth: 800, borderRadius: 0 }}>
                <Table>
                  <TableHeader
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHeaders}
                    rowCount={columns.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  {fetchingData ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <GenericTable data={qrCodes} columns={columns} emptyRows={emptyRows} />
                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Sin Resultados
                                </Typography>
                                {!!filterName ? (
                                  <Typography variant="body2">
                                    Sin Resultados &nbsp;
                                    <strong>&quot;{filterName}&quot;</strong>.
                                  </Typography>
                                ) : null}
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Scrollbar>
          {totalItems > params.limit ? (
            <Container style={{ marginTop: '20px' }} className="container-no-padding">
              <CustomPagination
                page={params.page}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Container>
          ) : null}
        </Container>
      </Container>
      <DynamicPopover open={open} anchorEl={open} handleCloseMenu={handleCloseMenu} menuItems={menuPopOver} />
    </>
  );
}
