import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import '../theme/css/custom-styles.css';
// @mui
import {
  Button,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { toast } from 'react-toastify';
import { GenericTable, TableHeader } from '../components/table';

import DynamicPopover from '../components/dynamic-pop-over';
import PaginationButton from '../components/pagination-button';
import Scrollbar from '../components/scrollbar';
import { useAPI } from '../hooks/APIContext';


import { RequestsPopUp } from '../components/pop-ups';
import { UserListToolbar } from '../sections/@dashboard/user';
import DeliveryStatusTranslator from "../utils/bellcomDeliveryStatus";


import QR_REQUEST from '../_mock/qr_request';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  return array
}

export default function QrRequests() {

  const api = useAPI();
  const [fetchingData, setFetchingData] = useState(false);

  const deliveryTranslator = new DeliveryStatusTranslator();
  const pageSize = 5; 
  const [totalItems, setTotalItems] = useState([]);
    
  const menuItems = [
    {
      label: 'Editar',
      onClick: () => {
        setOpenEditPopUp(true);
        handleCloseMenu();
      },
    },
    {
      label: 'Eliminar',
      onClick: () => {
        handleCloseMenu();
      },
      sx: { color: 'error.main' },
    },
  ];
 
  const [params, setParams] = useState(
    {
      search_text: "",
      start_date: "2022-01-01",
      end_date: "2025-01-01",
      limit: pageSize,
      page: 1,
    }
  );

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [selectedDate, setSelectedDate] = useState(null);

  const [openEditPopUp, setOpenEditPopUp] = useState(false);

  const [requests, setRequests] = useState([]);

  const [menuPopOver, setMenuPopOver] = useState(menuItems);

  const getStatusPopOverMenu = (item) => {
    const menuItemsStatus = [
      {
        label: 'Pendiente',
        onClick: () => {
          updateRequestStatus(deliveryTranslator.StatusKeys.PENDING, item);
          handleCloseMenu();
        },
      },
      {
        label: 'Enviado',
        onClick: () => {
          updateRequestStatus(deliveryTranslator.StatusKeys.IN_TRANSIT, item);
          handleCloseMenu();
        },
      },
      {
        label: 'Entregado',
        onClick: () => {
          updateRequestStatus(deliveryTranslator.StatusKeys.DELIVERED, item);
          handleCloseMenu();
        },
      },
    ];
    return menuItemsStatus;
  }

  const handleDateChange = (data, date) => {
    const { validatedValue } = date;
    if (validatedValue && validatedValue.length === 2) {
      const [date1, date2] = validatedValue.map((dateString) => new Date(dateString));
      
      const startDate = date1 < date2 ? date1.toISOString().split('T')[0] : date2.toISOString().split('T')[0];
      const endDate = date1 < date2 ? date2.toISOString().split('T')[0] : date1.toISOString().split('T')[0];
    
      setParams((prevState) => ({
        ...prevState,
        start_date: startDate,
        end_date: endDate,
        page: 1,
      }));
    }
    setSelectedDate(data)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const setSelectedRequest = (selectedRequest) => {
    setSelected(selectedRequest);
  }

  const closePopUp = () => {
    setOpenEditPopUp(false);
    setParams(
      {
        search_text: "",
        start_date: "2022-01-01",
        end_date: "2025-01-01",
        limit: pageSize,
        page: 1,
      }
    )
    setRequests([])
    setSelectedDate(null)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = QR_REQUEST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setParams((prevState) => {
      return {...prevState, page: newPage}
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilter = (value) => {
    setPage(0);
    setFilterName(value);
    setParams((prevParams) => {
      return {
        ...prevParams,
        search_text: value
      }
    })
  };

  const handleOpenMenu = (event, data) => {
    setOpen(event.currentTarget);
    setSelected(data);
  };

  const handleOptionsAction = (event, data) => {
    handleOpenMenu(event, data)
  }

  const updateRequestStatus = async (status, item) => {
    if (item.status === status) return;
    const updatedRequest = {...item, status};
    setFetchingData(true);
    await api.updateBellcomRequest(item.id, updatedRequest);
    await fetchRequests();
    toast.success(`Actualizado correctamente`, {
      position: 'top-right',
      autoClose: 3000, 
    });
  }
  
  const createQrAndAssociateRequest = async (selectedBellcom) => {
    const bellcomData = {}
    setFetchingData(true);
    const response = await api.createBellcom(bellcomData)
    const newRequestData = {...selectedBellcom, "bellcom_id": response.id}
    await api.updateBellcomRequest(selectedBellcom.id, newRequestData);
    await fetchRequests();
  }

  const fetchRequests = async () => {
    try {
      setFetchingData(true);
      const response = await api.getAllBellcomRequestsInInterval(params);
      setRequests(response.bellcom_requests);
      console.log(response.bellcom_requests);
      setTotalItems(response.total_bellcom_requests)
      setFetchingData(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }

  useEffect(() => {
    fetchRequests();
  }, [api, params]);

  const buttonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
  };
  
  const arrowStyles = {
    color: '#911109', // Customize the color as needed
    fontSize: '32px', // Adjust the font size to make the arrow bigger
  };

  const tableHeaders = [
    { id: 'owner_full_name', label: 'Nombre de Usuario', alignRight: false },
    { id: 'phone_number', label: 'Nº teléfono', alignRight: false },
    { id: 'address', label: 'Calle', align: 'left' },
    { id: 'zip_code', label: 'CP', alignRight: false },
    { id: 'bellcom_id', label: 'ID QR Bellcom', alignRight: false },
    { id: 'status', label: 'Estado', alignRight: false },  
    { id: 'options', label: '', alignRight: false },  
    { id: 'actions', label: '+', alignRight: false, sortable: false, labelStyles: { fontSize: '20px', color:"black", display: "flex", justifyContent: "center", verticalAlign: "center" }},  
  ];

  const columns = [
    { id: 'owner_full_name', label: 'owner_full_name',},
    { id: 'phone_number', label: 'phone_number', align: 'left' },
    { id: 'address', label: 'Calle', align: 'left' },
    { id: 'zip_code', label: 'zip_code', align: 'left' },
    { id: 'bellcom_id', label: 'bellcom_id', alignRight: false, 
      render: (row) => {
        return (!row.bellcom_id ?
        <Button 
          onClick={() => createQrAndAssociateRequest(row)}
          style={{
            border: 'none',
            padding: 0,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            margin: "auto",
            display: "flex",
          }}>
          <img src="/assets/images/qr/qr.png" alt="Is Active" />
        </Button> : 
        `# ${row.bellcom_id}`
      )}
    }, 
    { id: 'status', label: 'status', alignRight: false, 
      render: (row) => {
        const currentStatus = deliveryTranslator.translate(row.status);
        return (
        <div style={
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }
        }>
          {currentStatus.text}
          <Button style={buttonStyles} onClick={(event) => {
              handleOpenMenu(event, row);
              setMenuPopOver(getStatusPopOverMenu(row));
            }
          }>
            <ArrowDropDownIcon style={arrowStyles} />
          </Button>
        </div>
      )}
    }, 
    {
      id: 'actions',
      iconButton: true,
      icon: 'eva:more-horizontal-fill',
      iconColor: 'secondary', 
      handleClick: (event, data) => {
        setMenuPopOver(menuItems);
        handleOpenMenu(event, data);
      },
      iconWidth: 35,
      iconHeight: 35,
    },
  ];

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - QR_REQUEST.length) : 0;

  const filteredUsers = applySortFilter(QR_REQUEST, getComparator(order, orderBy), filterName);

  const isNotFound = !requests.length;

  const handlePageChange = (newPage, onPageChange, totalPages) => {
    if (newPage >= 0 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  function CustomPagination({ page, count, rowsPerPage, onPageChange}) {
    const totalPages = Math.ceil(count / rowsPerPage);
    return (
      <Container className="container-no-padding">
        <PaginationButton
          label="Anterior"
          onClick={() => {
            console.log("before");
            handlePageChange(page - 1, onPageChange, totalPages);
          }}
          isActive={false}
          disabled={page === 1}
          isFirst
        />
        {[...Array(totalPages)].map((_, index) => (
          <PaginationButton
            key={index}
            label={`${index + 1}`}
            onClick={() => {
              handlePageChange(index + 1, onPageChange, totalPages);
            }}
            isActive={page === index + 1}
          />
        ))}
        <PaginationButton
          label="Siguiente"
          onClick={() => {
            console.log(page);
            handlePageChange(page + 1, onPageChange, totalPages);
          }}
          isActive={false}
          disabled={page === totalPages}
          isLast
        />
      </Container>
    );
  }

  const inputConfigs = [
    {
      label: 'Buscar',
      icon: 'eva:search-fill',
      onChange: handleFilter,
      value: params.search_text,
    },
    {
      label: 'Fecha de creación',
      onChange: handleDateChange,
      value: selectedDate,
      isDateRangePicker: true,
      flexGrow: 1,
      sx: { marginLeft: '50px' },
    },
    {
      label: 'Estado',
      onChange: (event) => {
        let value = event.target.value;
        if(value === "all") {
          value = null;
        }
        setParams((prevState) => {
          return {...prevState, status: value}
        });
      },
      isSelectInput: true,
      flexGrow: 1,
      isAnimated: false,
      value: params.status || "all",
      sx: { marginLeft: '50px' },
      options: [
        { label: 'Todos', value: 'all' },
        { label: 'Enviados', value: deliveryTranslator.StatusKeys.IN_TRANSIT },
        { label: 'Entregados', value: deliveryTranslator.StatusKeys.DELIVERED },
        { label: 'Pendientes', value: deliveryTranslator.StatusKeys.PENDING },
      ]
    }
  ];
  

  return (
    <>
      <Helmet>
        <title> Información | Bellcom </title>
      </Helmet>
      <Container sx={{borderRadius: 0, background: "none"}}>
        {
            openEditPopUp ? <div><RequestsPopUp request={selected} onClosePopUp={closePopUp}  open={openEditPopUp}/></div>  : null
        }
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom sx={{ color: '#911109', fontSize: 20 }}>
            Solicitudes QRs
          </Typography>
          <Typography variant="paragraph" gutterBottom sx={{ color: '#000000', fontSize: 15 }}>
            Solicitudes de QRs bellcom
          </Typography>
        </Stack>

        <UserListToolbar inputs={inputConfigs} />

        <Container sx={{borderRadius: 0, background: "none", padding: "0"}} className='container-no-padding'>
          <Scrollbar>
            <Card sx={{borderRadius: 0}}>
            <TableContainer sx={{ minWidth: 800, borderRadius: 0 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHeaders}
                  rowCount={requests.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                { fetchingData ? <CircularProgress /> : (
                  <>
                    <GenericTable
                      data={requests}
                      columns={columns}
                      emptyRows={emptyRows}
                      handleOpenMenu={handleOpenMenu}
                    />
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Sin Resultados
                              </Typography>
                              {!!filterName ? 
                                <Typography variant="body2">
                                  Sin Resultados &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                </Typography>
                                : null 
                              }

                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      )}
                    </>
                )}
              </Table>
            </TableContainer>
            </Card>
          </Scrollbar>
          
          {
            totalItems > params.limit ?           
            <Container style={{ marginTop: '20px' }} className='container-no-padding'>
                <CustomPagination
                page={params.page}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Container> : null
          }
        </Container>
      </Container>
      <DynamicPopover
        open={open}
        anchorEl={open}
        handleCloseMenu={handleCloseMenu}
        menuItems={menuPopOver}
      />
    </>
  );
}
