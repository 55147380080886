import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify';
import auth from '../../../firebase/firebase';
// components
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = async () => {
    try {
      console.info("executing login");
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error.message);
      toast.success(`Usuario inválido`, {
        position: 'top-right',
        autoClose: 3000, 
      });
    }
    navigate('/dashboard/user', { replace: true });
  };

  const textFieldStyles = {
    marginBottom: '16px', // Adjust the spacing as needed
    '& input': {
      border: "0px",
      color: "white",
    },
    '& label': {
      color: "#D1D1D1"
    },
    '& fieldset': {
      border: "0px",
      borderBottom: "2px solid white",
      borderRadius: "0",
    },
    '&:focus-within fieldset, &:focus-visible fieldset': {
      borderBottom: '2px solid #8D0800 !important',
    },
    '&:focus-within label, &:focus-visible label': {
      color: 'white',
    },
    '&:nth-child(1)': {
      marginTop: '40px',
    },
    '&:nth-child(2)': {
      marginTop: '40px',
      marginBottom: '50px', 
    },
  };

  const buttonStyle = {
    backgroundColor: "#FFFFFF", 
    color: "#8D0800",
    height: "50px",
    borderRadius: 0,
    padding: "20px",
    fontWeight: "600",
    fontSize: "20px",
    '&:hover': {
      backgroundColor: "#8D0800", 
      color: "#FFFFFF",
    }
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email" sx={textFieldStyles} value={email} onChange={(event) => setEmail(event.target.value)}/>

        <TextField
          sx={{...textFieldStyles, marginTop: "50px"}} 
          name="password"
          label="Contraseña*"
          value={password} onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={buttonStyle}>
        Login
      </LoadingButton>
    </>
  );
}
