import { useState } from 'react';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal  from '@mui/material/Modal';
import Typography  from '@mui/material/Typography';
import LabeledInput from '../labeled-button/labeled-button';
import DynamicPopover from '../dynamic-pop-over'
import DeliveryStatusTranslator from "../../utils/bellcomDeliveryStatus"
import { useAPI } from '../../hooks/APIContext';

import '../../theme/css/custom-styles.css';

const CustomerPopUp = ({onClosePopUp, open, request}) => {
  const api = useAPI();
  const deliveryTranslator = new DeliveryStatusTranslator();

  const [openPopOver, setOpenPopOver] = useState(false);
   
  const [currentRequest, setCurrentRequest] = useState({...request});

  const handleInputChange = (label, value) => {
    setCurrentRequest(prevCustomer => ({
      ...prevCustomer,
      [label]: value
    }));
  };

  const menuItems = [
    {
      label: 'Pendiente',
      onClick: () => {
        setRequestStatus(deliveryTranslator.StatusKeys.PENDING);
        handleCloseMenu();
      },
    },
    {
      label: 'Enviado',
      onClick: () => {
        setRequestStatus(deliveryTranslator.StatusKeys.IN_TRANSIT);
        handleCloseMenu();
      },
    },
    {
      label: 'Entregado',
      onClick: () => {
        setRequestStatus(deliveryTranslator.StatusKeys.DELIVERED);
        handleCloseMenu();
      },
    },
  ]; 

  const onClickBellcom = (event) => {
    setOpenPopOver(event.currentTarget)
  }

  const onSave = async () => {
    await api.updateBellcomRequest(currentRequest.id, currentRequest);
    toast.success(`Solicitud actualizada Correctamente`, {
      position: 'top-right',
      autoClose: 3000, 
    });
    onClosePopUp();
  }

  const setRequestStatus = (status) => {
    if (currentRequest.status === status) return;
    const newRequests = {...currentRequest};
    newRequests.status = status;
    setCurrentRequest(newRequests);
  }

  const handleCloseMenu = () => {
    setOpenPopOver(null);
  };

  const buttonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  
  const arrowStyles = {
    color: '#911109',
    fontSize: '32px',
  };

  const handleClose = () => {
    onClosePopUp(false);
  };
  
  const originalDateString = request.request_date;
  const dateObject = new Date(originalDateString);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day}`;
  const status = deliveryTranslator.translate(currentRequest.status);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: "500px",
        }}
      >
        <>
        <Box 
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 0,
            minWidth: 300,
            maxWidth: '80%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FAF5EF'
          }}
        >
          <Box
            sx={{
              bgcolor: '#911109',
              color: 'white',
              padding: '16px',
              textAlign: 'left',
              fontSize: '20px',
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ color: '#ffffff', fontWeight: 100, margin: 0 }}>
              Detalle de solicitud
            </Typography>
          </Box>

          <Container sx={{
            flexDirection: "row",
            display: "flex",
            paddingTop: "30px"
          }}>
            <LabeledInput 
              label='Nombre de Usuario'
              flexGrow={1}
              overrideStyles={{
                width: 450,
                marginRight: 40
              }}
              value={currentRequest.owner_full_name}
              onChange={value => handleInputChange('owner_full_name', value)}
            />
            <LabeledInput label='Nº de teléfono' flexGrow={1}
              overrideStyles={{
                width: 200,
                marginRight: 40
              }}
              value={currentRequest.phone_number}
              onChange={value => handleInputChange('phone_number', value)}
            />
            <LabeledInput 
                overrideStyles={{
                width: 380,
                marginRight: 35
              }}
              label='Fecha de Creación' 
              flexGrow={1}
              value={formattedDate}
              isActive={false}
            />
          </Container>

          <Container sx={{
              flexDirection: "row",
              display: "flex",
            }}>
            <LabeledInput label='Calle y Número' flexGrow={1}
              overrideStyles={{
                width: 450,
                marginRight: 42
              }}
              value={currentRequest.address}
              onChange={value => handleInputChange('address', value)}
            />
            <LabeledInput label='Código Postal' flexGrow={1}
              overrideStyles={{
                width: 200,
                marginRight: 40
              }}
              value={currentRequest.zip_code}
              onChange={value => handleInputChange('zip_code', value)}
            />
            <LabeledInput label='Ciudad' flexGrow={1}
              overrideStyles={{
                width: 380,
              }}
              value={currentRequest.city}
              onChange={value => handleInputChange('city', value)}
            />
          </Container>
          <Container sx={{
            flexDirection: "column",
            display: "flex",
            paddingTop: "10px",
            paddingBottom: "30px"
          }}>
            <Typography component="div" variant="subtitle1" display="flex" flexDirection="column" className='text-color'>
              Estado
            </Typography>
            <Container
              sx={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '30px',
                backgroundColor: 'white',
                borderBottom: '1px solid #cccccc',
                height: '50px',
                padding: '0',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'space-between',
                  maxWidth: '120px',
                }}
                className='container-no-padding'
              >
                <div>
                  <img
                    src={status.image}
                    alt={status.text}
                  />
                </div>
                <Typography component="div" variant="subtitle1" className='row-text-color'>
                  {status.text}
                </Typography>
              </div>
              <Button style={buttonStyles} onClick={(event) => onClickBellcom(event)}>
                <ArrowDropDownIcon style={arrowStyles} />
              </Button>
            </Container>
          </Container>
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px',
            }}
          >
            <Button 
              onClick={onSave}
              sx={{
                backgroundColor:"#000000",
                color: "#ffffff",
                height: "50px",
                width: "30%",
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: '#63CD71', 
                },
              }}>
              Guardar
            </Button>
          </Container>
        </Box>
        <DynamicPopover
            open={openPopOver}
            anchorEl={openPopOver}
            handleCloseMenu={handleCloseMenu}
            menuItems={menuItems}
          />
        </>
      </Modal>
    </>
  );
}

CustomerPopUp.propTypes = {
  onClosePopUp: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  request: PropTypes.object,
};

export default CustomerPopUp;