import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        bgcolor: "black",
        color: "white",
        borderBottom: "1px solid white",
        borderRadius: 0,
        paddingLeft: "10px",
        height: "40px",
        fontWeight: 'fontWeightMedium',
        '&.active': {
          color: '#911109',
          bgcolor: 'white',
          border: "none",
          borderLeft: "3px solid #911109",
        },
      }}
    >
      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
